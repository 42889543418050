import validation from "@/mixins/validation";
import { email, required } from "vuelidate/lib/validators";
import { $http } from "@/utils/https";

export default {
  name: "ContactsForm",
  mixins: [validation],
  data() {
    return {
      payload: {
        name: '',
        email: '',
        comment: ''
      },
      validationErrors: {},
      loading: false
    }
  },
  validations() {
    const validation = {
      payload: {
        name: {
          required,
        },
        email: {
          required,
          email,
        },
        comment: {
          required,
        }
      }
    }

    return validation;
  },
  computed: {
    nameErrors() {
      return this.computeErrors("payload", "name", {
          required: "validationRequired",
      });
    },
    emailErrors() {
      return this.computeErrors("payload", "email", {
        required: "validationRequired",
        email: "validationEmail"
      });
    },
    commentErrors() {
      return this.computeErrors("payload", "comment", {
        required: "validationRequired",
      });
    },
  },
  methods: {
    submit() {
      this.loading = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.loading = false;
        return
      }

      $http.post('v1/store_contact_form', this.payload).then(() => {
        this.$toasted.success("Успішно відправлено");
        this.$v.$reset();
        this.payload = {
          name: '',
          email: '',
          comment: ''
        }
      }).catch(() => {
        this.$toasted.error("Помилка надсилання даних");
      }).finally(() => {
        this.loading = false;
      })
    }
  }
}
