import { mapActions, mapGetters } from "vuex";
import ContactsForm from "@/modules/pages/components/contacts-form/index.vue";

export default {
  name: "contact-page",
  watch: {
    $route() {
      this.fetchPage("contact-page");
    },
  },
  created() {
    this.fetchPage("contact-page");
  },
  components: {
    ContactsForm
  },
  computed: {
    ...mapGetters({
      contacts: "setting/variables",
      page: "pages/page",
    }),

    getContactsWithMap() {
      return this.contacts?.contactInfo.filter(info => !!info.map);
    },

    getContactsWithoutMap() {
      return this.contacts?.contactInfo.filter(info => !info.map);
    },
  },
  methods: {
    ...mapActions({
      fetchPage: "pages/GET_PAGE",
    }),
    /**
     * @param {keyof ContactPage|string} field
     * @return {string}
     */
    getContact(field) {
      return this.contacts?.contactPage[field] || "";
    },
  },
};
